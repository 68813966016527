<style lang="scss" scoped>
.cell_title,
.cell_value {
    color: $font_color_thd;
    font-size: .24rem;
}

.product_detail {
    width: 100%;
    height: 100%;
    padding: 0.2rem 0.4rem 0;
	box-sizing: border-box;
}
.card_box{
	width: 100%;
	padding: 0.24rem;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0,0,0,0.2);
	border-radius: 0.12rem ;
}

.main_title {
    font-size: .3rem;
    color: $font_color_main;
    font-weight: bold;
    display: flex;
	justify-content: flex-start;
	align-items: center;
}

.sec_title {
    font-size: .28rem;
    color: $font_color_main;
    font-weight: bold;
}

.title {
    height: .8rem;
    font-size: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $color_main;
    // color:$color_1;
}

.banner {
    display: flex;
    justify-content: center;

    img {
        width: 6.7rem;
        height: 3.74rem;
    }
}

.price_info {
    padding: 0.2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
        color: $color_active;
        display: flex;
        align-items: baseline;

        .integer {
            font-size: .4rem;
            font-weight: bold;
            line-height: .42rem;
        }

        .icon {
            font-size: .3rem;
        }

        .float {
            font-size: .36rem;
        }
    }

    .sales {
        color: $font_color_info;
        font-size: .24rem;
    }
}

.product {
    padding-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: .25rem;
    }
}

.product_info {
    padding-bottom: 0.2rem;
    font-size: .24rem;
    color: $font_color_main;
    display: flex;
    align-items: center;

    .line {
        width: 1px;
        height: .25rem;
        background-color: $font_color_main;
        margin: 0 .16rem;
    }

    .icon {
        margin-left: .16rem;
    }
}

.product_tags {
    display: flex;
    align-items: center;
    padding: 0.2rem 0;

    li {
        height: .32rem;
        font-size: .24rem;
        padding: .04rem .12rem;
        color: $color_active_2;
        background-color: rgba($color: $color_main, $alpha: .1);
        margin-left: .1rem;
    }

    .active {
        color: $color_active;
        background-color: rgba($color: $color_active, $alpha: .1);
        margin-left: 0;
    }
}

.plain {
    border-bottom:0.02rem solid $b_border_color ;
	padding-bottom: 0.2rem;
}

.plain_cont {
	padding: 0.2rem 0;
}

.duty {
    padding-top: 0.2rem 0;
    margin-top: .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        font-size: .24rem;
        color: $font_color_main;

        .btn_text {
            margin-right: .1rem;
        }
    }
}
::v-deep .ly-tab-item {
	align-items: flex-start !important;
	&:first-child{
		margin-left: 0;
	}
}
.duty_list {
	position: relative;

    // padding: 0 .3rem;
    li {
        padding: .24rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $font_color_main;
        font-size: .24rem;

        // line-height: .24rem;
        .money_box {
            display: flex;
            align-items: center;
        }

        .money {
            margin-right: .1rem;
        }
    }
    .see_more_line{
		position: absolute;
		bottom: 1.2rem;
		left: 0;
		height: 0.6rem;
		width: 100%;
		background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
	}

    .see_more {
        margin: .12rem 0 .06rem 0;
        justify-content: center;

        .text {
            margin-right: .1rem;
        }
    }

    .line {
        padding: 0;
        height: 1px;
        background-color: #ddd;
    }
    ::v-deep(.van-cell){
		padding: 0.2rem 0;
		border-bottom:0.02rem solid $b_border_color;
		font-size: 0.28rem;
		&:last-child{
			border-bottom: 0;
		}
	}
}

.count {
    margin: 0.2rem;
    height: .8rem;
    border-radius: 8px;
    font-size: .3rem;
    text-align: center;
    color: #fff;
    background-color: $color_main;
    line-height: .8rem;
}

.more_detail {
    padding: 0 .3rem;
    font-size: .24rem;
    color: $font_color_info;

    .link {
        color: $color_main;
    }
}

.tab {
    padding: .2rem .3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0;
    background-color: #fff;

    li {
        text-align: center;
    }

    .text {
        font-size: .3rem;
        font-weight: bold;
    }

    span {
        display: inline-block;
        width: .3rem;
        height: .06rem;
        background-color: #fff;
        margin-top: .14rem;
    }

    .active .text {
        color: $color_main;
    }

    .active span {
        background-color: $color_main;
    }
}

.tab_float {
    position: fixed;
    top: .88rem;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 4px #ddd;
}



.buy_btn_box {
    .cont {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.1rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #EEEEEE;
        font-size: 0;

        .price {
            background-color: #fff;
            width: 3rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                font-size: .24rem;
                color: $font_color_main;
                line-height: .24rem;
                margin-right: .08rem;
            }

            .icon {
                font-size: .24rem;
                color: #FF5702;
            }

            .num {
                font-size: .36rem;
                color: #FF5702;
                line-height: .4rem;
            }

            .price_content {
                display: flex;
                align-items: baseline;
            }
        }

        .btn {
            height: 100%;
            flex: 1;
            font-size: .28rem;
            line-height: 1.1rem;
            text-align: center;
            color: #fff;
            // background-image: url(../../../../assets/icon/buy.png);
            background-image: url(../../../../assets/icon/buy.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }

    .back_dom {
        height: 100%;
        height: 1.1rem;
    }

}

::v-deep .age_box {
    position: relative;
    right: 6px;

    .van-dropdown-menu__bar {
        box-shadow: none;
    }

    .van-ellipsis {
        font-size: .28rem;
    }
}

.age_cell_title {
    display: flex;
    align-items: center;
    font-size: .28rem;
    color: #333;
}

.calculateForm_value {
    color: #333;
}

.calculateForm_content {
    padding-bottom: 1.1rem;
}

.calculateForm_product_name {
    font-size: .32rem;
    font-weight: bold;
}

.enter_process_pop {
    z-index: 3001 !important;

    .content {
        font-size: .24rem;
        color: $font_color_main;
        padding: .2rem .3rem;
    }

    .link {
        color: $color_main;
    }

    .section {
        padding: .2rem 0;
        text-indent: 2em;
        line-height: .4rem;
    }

    .footer {
        text-indent: 2em;
    }
}

.about {
    color: $font_color_main;
    font-size: 0;
    text-align: center;
    padding: .3rem .3rem .8rem;

    .header {
        font-size: .3rem;
        line-height: .48rem;
        font-weight: bold;
    }

    .title {
        font-size: .3rem;
        line-height: .4rem;
        margin-top: .2rem;
        margin-bottom: .1rem;
    }

    .section {
        font-size: .24rem;
    }

    ul {
        margin-top: .6rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: .2rem;

        li {
            display: flex;
            align-items: center;

            .icon {
                font-size: .6rem;
                color: $font_color_info;
            }

            .text {
                font-size: .16rem;
                margin-left: .1rem;

                p {
                    line-height: .26rem;
                }
            }
        }
    }
}

::v-deep .calculateForm_content {
    .van-dropdown-item__content {
        max-height: 30vh;
    }
}

.line_box {
    padding: .5rem 0;

    .line {
        height: 1px;
        background-color: #ddd;
    }
}

.weChat_warning {
    font-size: .4rem;
    text-align: center;
}

::v-deep .ly-tab-item {
    align-items: flex-start !important;
}

.nav_menu {
    float: right;
    font-size: .30rem;
    margin-left: -2rem;
}
.title_name{
	color: $font_color_main;
}
.line_active{
	width: 0.08rem;
	height: 0.32rem;
	background: $color_active_5;
	margin-right: 0.2rem;
}
</style>
<template>
    <div class="product_detail" ref="body" @scroll.passive="touchmove">
        <div class="weChat_warning" v-if="weChatWarningShow">
            <h4>请在微信或支付宝中打开</h4>
        </div>
        <div>
            <top-nav ref="nav" backImage bgColor="#F6F6FB" @back="back">
				<span class="title_name">产品详情</span>
			</top-nav>
            <div class="banner">
                <img :src="productInfo.proPicUrlIndexBig" alt="">
            </div>
            <div class="price_info">
                <span class="price">
                    <span class="icon">￥</span>
                    <span class="integer">{{ priceInt }}</span>
                    <span class="float">{{ '.' + priceFloat }}</span>
                </span>
                <span class="sales">销量 {{ productInfo.popularity }}</span>
            </div>
            <!-- 保险名称 -->
            <div class="product">
                <span class="name main_title">{{ productInfo.name }}</span>
                <img :src="insurerInfo.iconUrlSmallbrand" alt="">
            </div>

            <div class="product_info">
                <span>保障年龄：</span>
                <span>{{ productInfo.insureAge }}</span>
                <span class="line"></span>
                <span>保障期限：</span>
                <span> {{ productInfo.insureTime }}</span>
                <span v-if="false" class="icon arrow_top"></span>
            </div>
            <!-- 保障计划 -->
			<div class="card_box">
				<div class="plain main_title"><div class="line_active"></div>保障计划</div>
                <div class="plain_cont">
                    <ly-tab v-model="selectedIndex" @change="protectPlainChange" :items="protectPlainList"
                        :options="options">
                    </ly-tab>
                </div>
                <!-- 保障责任 -->
                <div class="duty" v-if="false">
                    <div class="btn" v-if="false">
                        <span class="btn_text">展开</span>
                        <span class="arrow_down_info"></span>
                    </div>
                </div>
                <ul class="duty_list">
                    <van-cell-group :border="false">
                        <template v-for="(item, index) in dutyList">
                            <van-cell :key="index" v-if="item.price" :title="item.contentName" :value="item.price"
                                @click="showDutyDetail(item)" title-class="cell_title" value-class="cell_value"
                                :border="false" is-link />
                        </template>
                    </van-cell-group>
					<li class="see_more_line" v-if="!dutyShowAll&&dutyList.length"></li>

                    <li class="see_more" v-show="!dutyShowAll">
                        <span class="text" @click="dutyShowAll = true">查看更多</span>
						<span class="arrow_down_info"></span>
                    </li>
                    <li class="line"></li>
                </ul>

                <!-- 保费试算 -->
                <div class="count" @click="calculate">保费试算</div>
                <div class="more_detail">
                    更多详情，请阅读
                    <span class="link" @click="showInsuranceInformation">《投保须知》</span>
                    和
                    <span class="link" @click="openProvisionPage">《投保条款》</span>
                </div>

            </div>
			<!-- 产品解读 -->
			<insureProductNarrate v-show="informationAreaShow" :information="information" :questionList="questionListShow"/>
            <price-buy-btn :price="price" @buy="buy"></price-buy-btn>

            <!-- 信息弹出层 -->
            <bottom-info-pop v-model="infoPop" :title="infpPopData.title" :content="infpPopData.text"></bottom-info-pop>

            <!-- 保费试算 -->
            <van-action-sheet v-model="calculatePop" title="保费试算">
                <div class="calculateForm_content">
                    <van-cell-group :border="false">
                        <van-cell :title="productInfo.name" title-class="calculateForm_product_name"></van-cell>
                        <van-cell title="购买份数" :value="`${calculateForm.num}份`" value-class="calculateForm_value">
                        </van-cell>
                        <!-- <van-cell title="承保年龄" title-class="age_cell_title" :border="true">
                            <van-dropdown-menu slot="extra" class="age_box" direction="up">
                                <van-dropdown-item v-model="calculateForm.age" :disabled="ageLimit.length === 1"
                                    :options="ageLimit" />
                            </van-dropdown-menu>
                        </van-cell> -->
                        <van-cell title="保障期限" title-class="age_cell_title" :border="true">
                            <van-dropdown-menu slot="extra" class="age_box" direction="up">
                                <van-dropdown-item v-model="calculateForm.totalDays" @change="getPrice"
                                    :options="insureTimeList" />
                            </van-dropdown-menu>
                        </van-cell>
                    </van-cell-group>
                </div>
            </van-action-sheet>

            <!-- 购买确认弹出层 -->
            <van-action-sheet v-model="warningPop" class="enter_process_pop" title="确认进入投保流程" @cancel="stopKhs" @click-overlay="stopKhs">
                <div class="content">
                    <p class="header">尊敬的客户：</p>
                    <p class="section">您即将进入投保流程，请仔细阅读保险条款。本保险产品由《<span class="link"
                            v-text="insurerFullName"></span>》承保，由耀保网协助理赔。</p>
                    <p class="footer">投保前请你仔细阅读<a class="link" :href="docToConsumerLink" target="_blank">客户告知书</a></p>
                </div>
                <div class="count" @click="enterProcess">确认开始投保</div>
            </van-action-sheet>

        </div>
    </div>
</template>
<script>
import { Cell, CellGroup, ActionSheet, DropdownMenu, DropdownItem } from 'vant';
import { loginNoPwd, productDetail, productDictInfo, getPrice, getInsuerInfo } from '@/request/api'
import { http_insDatingBack } from '@/request/common'
import insureProductNarrate from "@/components/cpsInsureProductNarrate"


export default {
    name: 'cpsGuoRen_index',
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [ActionSheet.name]: ActionSheet,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
		insureProductNarrate

    },
    computed: {
        // 保障责任
        dutyList() {
            let result = [];
            if (this.protectPlainList.length) {
                if (!this.dutyShowAll) {
                    result = this.protectPlainList[this.selectedIndex].contents.slice(0, 3);
                } else {
                    result = this.protectPlainList[this.selectedIndex].contents
                }
            }
            return result;
        },
        priceInt() {
            let result = '0';
            if (this.protectPlainList.length) {
                let plain = this.protectPlainList[this.selectedIndex];
                let fullPrice = this.$base.floatPrice(plain.viewPrice);
                if (fullPrice) {
                    result = fullPrice.split('.')[0]
                }
            }
            return result;
        },
        priceFloat() {
            let result = '00';
            if (this.protectPlainList.length) {
                let plain = this.protectPlainList[this.selectedIndex];
                let fullPrice = this.$base.floatPrice(plain.viewPrice);
                if (fullPrice) {
                    result = fullPrice.split('.')[1]
                }
            }
            return result;
        },
        questionListShow() {
            let result = [];
            if (this.questionList.length) {
                if (!this.questionListShowAll) {
                    result = this.questionList.slice(0, 3);
                } else {
                    result = this.questionList;
                }
            }
            return result;
        },
    },
    data() {
        return {
            fromName: '',
            user: '',
            weChatWarningShow: false,
            scrollY: 0,
            // 产品信息
            productInfo: {},
            banner: '',

            // 保险公司信息
            insurerInfo: {},

            // 保障计划
            protectPlainList: [],
            selectedIndex: 0,
            options: {
                activeColor: '#1d98bd',
                labelKey: 'comboName'
                // 可在这里指定labelKey为你数据里文字对应的字段名
            },
            dutyShowAll: false,

            // 底部信息弹窗层
            infoPop: false,
            infpPopData: {
                title: '',
                text: ''
            },

            // tab是否要浮动在顶部
            tabFloat: false,
            tabOffsetTop: 0,
            tabActive: 0,

            // 其它信息
            information: {},
            informationAreaShow: false,

            // 保费试算弹窗
            calculatePop: false,
            // 承保年龄
            calculateForm: {
                num: 1,
                age: NaN,
                totalDays: NaN,
            },
            // 承保年龄
            ageLimit: [],
            // 保障期限
            insureTimeList: [],

            price: 0,

            // 理赔指南
            guideList: [],

            // 常见问题
            questionListShowAll: false,
            questionList: [],

            warningPop: false,
            docToConsumerLink: '',
            insurerFullName: '',

            product: {},
            otherInfo: {},
            backTo:'',

            userId: '',
        }
    },
    created() {
        this.getQuery()
        // this.login()
    },

    methods: {
        stopKhs() {
            khs.stop()
        },

        back() {
            if (this.fromName === '/') {
                return this.$router.push({ name: 'cpsHome', query: { code: JSON.parse(localStorage.getItem('cps_code')).code } })
            }else if(this.fromName.includes('cpsSafeguardClause')){
                this.$router.push({ name: 'cpsHome', query: { code: JSON.parse(localStorage.getItem('cps_code')).code } })
            } else if (this.fromName) {
                return this.$router.push(this.fromName)
            }
            this.$router.push({ name: 'cpsHome', query: { code: JSON.parse(localStorage.getItem('cps_code')).code } })
        },
        // 获取地址code
        getQuery() {
            let query = this.$route.query
            if (query) {
                this.$store.commit('set_cps_productId', query.productId)
                if(query.productId){
                    this.getProductDetail(query.productId)
                }
            }
            this.userId = this.$store.state.cps_brindInfo.userId
        },
        // 登录
        // async login() {
        //     const res = await loginNoPwd(this.user)
        //     if (res.hasOwnProperty('userInfo')) {
        //         this.getProductDetail(this.$store.state.cps_productId)
        //     }
        // },

        // 获取产品信息
        getProductDetail(productId) {
            productDetail(productId).then(res => {
                this.productInfo = res.product;
                this.insurerInfo = res.insurer;

                // this.protectPlainList   = res.combos;
                this.information = res.information;
                this.questionList = res.questionList;
                // 获取保障计划
                this.getPlain()
                this.informationAreaShow = true;
                this.tabActive = this.information.productCase ? 0 : 1;
                // 特殊用户定制图片
                // let conf = vip[this.$store.state.user];
                // if (conf) {
                //     if (conf.banner && (!conf.product || conf.product === this.$store.state.productId)) {
                //         this.productInfo.proPicUrlIndexBig = require(`@/assets/image/userSpecial/${conf.banner}`)
                //     }
                // }
            })
        },
        // 获取计划
        getPlain() {
            let send = {
                comboId: '',
                backInsureFlag: 1
            }
            getInsuerInfo(this.productInfo.id, send).then(res => {
                this.$store.commit('set_cps_immediateExtendTime', res.product.immediateExtendTime)
                this.protectPlainList = res.combos;
                this.otherInfo = res.formInput.otherInfo
                if (this.protectPlainList.length) {
                    this.protectPlainChange(this.protectPlainList[0])
                }
            })
        },

        // 切换保障计划
        protectPlainChange(palinDict) {
            this.$store.commit('set_cps_plainId', palinDict.comboId)
            this.price = this.$base.floatPrice(palinDict.viewPrice)
        },

        // 年龄段去重
        unique(arr) {
            let result = [];
            let compareValList = [];
            arr.forEach(item => {
                if (!compareValList.includes(item.text)) {
                    result.push(item)
                    compareValList.push(item.text)
                }
            });
            return result;
        },

        // 保费试算
        calculate() {
            productDictInfo(this.$store.state.cps_productId, this.protectPlainList[this.selectedIndex].comboId).then(res => {
                this.ageLimit = res.ageBelongList[0].ageLimit.map((item, index) => {
                    return {
                        text: `${item.min}-${item.max}周岁`,
                        value: index,
                        data: item
                    }
                });
                this.ageLimit = this.unique(this.ageLimit)

                this.insureTimeList = res.insureTimeList.map(item => {
                    item.text = item.view_time;
                    item.value = item.totalDays;
                    return item
                });

                if (this.ageLimit.length) {
                    this.calculateForm.age = this.ageLimit[0].value;
                }

                if (this.insureTimeList.length) {
                    this.calculateForm.totalDays = this.insureTimeList[0].value;
                    this.getPrice(this.calculateForm.totalDays)
                }
                this.calculatePop = true;
            })
        },

        // 获取价格
        getPrice(val) {
            let timeDict = this.insureTimeList.filter(item => item.value === val)[0];
            let send = {
                "comboId": this.protectPlainList[this.selectedIndex].comboId,
                "eleView1": "",
                "eleView2": "",
                "insureTime": timeDict.insure_time,
                "insureTimeUnit": timeDict.insure_time_unit,
                "priceId": timeDict.price_id,
                "proId": this.productInfo.id,
                "sex": "",
                "viewAgeBelong": "",
                "viewTime": timeDict.view_time
            };
            getPrice(send).then(res => {
                this.price = this.$base.floatPrice(res.priceInfo.price)
            })
        },


        // 显示底部弹出层
        showInfoPop(title, contentText) {
            this.infpPopData = {
                title: title,
                text: contentText
            };
            this.infoPop = true;
        },

        // 查看保障责任详情
        showDutyDetail(detail) {
            this.showInfoPop(detail.contentName, detail.contentContent)
        },

        // 投保须知
        showInsuranceInformation() {
            this.showInfoPop('投保须知', this.information.hint)
        },

        // 打开投保条款页面
        openProvisionPage() {
            this.$router.push({
                name: 'cpsSafeguardClause',
                params: {
                    productId: this.$store.state.cps_productId
                }
            })
        },

        // 进入流程
        enterProcess() {
            http_insDatingBack({ content: '确认进入投保流程', proId: this.productInfo.id })
            this.warningPop = false;
            this.$nextTick(() => {
                // let routeName = this.$store.state.user === 'c5dc7967a2d3dc0e5baf3f23b73f64a3' ? 'createOrderFixed' : 'createOrder';
                let routeName = 'cpsGrCreateOrder';
                // let conf = vip[this.$store.state.user];
                // if (conf) {
                //     if (conf.orderPageName && (!conf.product || conf.product === this.$store.state.productId)) {
                //         routeName = conf.orderPageName
                //     }
                // }
                this.$store.commit('set_cps_createOrderRouteName', routeName)
                this.$router.push({
                    name: routeName,
                    params: {
                        productInfo: this.productInfo,
                        information: this.information,
                        product: this.product,
                        otherInfo: this.otherInfo,

                        detailTransNo: this.detailTransNo
                    }
                })
            })
        },

        buy() {
            khs.start(this.userId)
            let send = {
                comboId: this.protectPlainList[this.selectedIndex].comboId,
                backInsureFlag: ''
            }
            getInsuerInfo(this.productInfo.id, send).then(res => {
                this.product = res.product;
                if (res.bookList) {
                    let bookList = res.bookList.filter(item => item.docType === '03');
                    if (bookList.length) {
                        this.docToConsumerLink = res.productFileServer + bookList[0].docLink;
                    }
                }
                this.insurerFullName = res.insurer.insurerFullName;
                this.warningPop = true;
            })
        },
    },

    beforeRouteLeave(to, from, next) {
        this.scrollY = document.querySelector('.product_detail').scrollTop
        next()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$nextTick(() => {
                if (from.name !== 'cpsGrCreateOrder') {
                    vm.fromName = from.fullPath
                }
                vm.$refs.body.scrollTop = vm.scrollY
            })
        })
    },
};
</script>