<style lang="scss" scoped>
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.tab_content {
	box-sizing: border-box;
	width: 100%;
	padding-bottom: 0.3rem;
	.banner {
		width: 100%;
		margin-bottom: 0.8rem;
	}
	.guide {
		.main_title {
			line-height: 0.3rem;
		}
		.sec_title {
			line-height: 0.28rem;
			margin-top: 0.3rem;
			margin-bottom: 0.2rem;
		}
		p {
			color: $font_color_sec;
			font-size: 0.24rem;
			line-height: 0.36rem;
			margin-bottom: 0.63rem;
		}
	}
	.question {
		padding-top: 0.32rem;
		.main_title {
			padding-bottom: 0.09rem;
			line-height: 0.3rem;
		}
		.cont {
			padding-top: 0.34rem;
		}
		.cont_line {
			border-top: 1px solid $b_border_color;
		}
		.que,
		.ans {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}
		.icon {
			flex-shrink: 0;
			margin-right: 0.16rem;
		}
		.text {
			font-size: 0.24rem;
			line-height: 0.3rem;
			color: $font_color_main;
		}
		.ans {
			margin-top: 0.24rem;
			margin-bottom: 0.24rem;
			.text {
				color: $font_color_sec;
			}
		}
		.more_ans {
			font-size: 0;
			margin-bottom: 0.3rem;
			margin-left: 0.46rem;
			display: flex;
			align-items: center;
			.text {
				font-size: 0.24rem;
				color: $color_active_3;
				line-height: 0.24rem;
				margin-right: 0.1rem;
			}
		}
	}
	.example {
		p {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.36rem;
			margin-top: 0.2rem;
			text-indent: 2em;
		}
	}
	.see_more {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0;
		span {
			font-size: 0.24rem;
			color: $font_color_main;
			line-height: 0.24rem;
		}
		.icon {
			margin-left: 0.1rem;
		}
	}
}

::v-deep .product_advantage {
	font-size: 12px;
    word-break: break-all;
	margin-top: 0.2rem;
	img {
		max-width: 100%;
	}
}

::v-deep .guide_content_box {
	margin-top: 0.3rem;
    word-break: break-all;
	font-size: 12px;
	img {
		max-width: 100%;
	}
}
.padding_content{
	padding: 0.2rem 0.3rem 0;
}
.mt_6{
    margin-top: 0.6rem;
}
::v-deep .text_break{
    word-break: break-all;
	img{
		display: block;
		max-width: 100%;
	}
}
.card_box{
	width: 100%;
	padding: 0.24rem;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0,0,0,0.2);
	border-radius: 0.12rem ;
}
.mt_4{
	margin-top: 0.4rem;
}
.line_active{
	width: 0.08rem;
	height: 0.32rem;
	background: $color_active_5;
	margin-right: 0.2rem;
}
.flex_start{
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
}

</style>

<template>
	<div class="pro_narrate">
		<div class="tab_content">
			<div class="card_box mt_4" v-if="information.productCase">
				<div  class="main_title flex_start"><div class="line_active"></div><span>产品解读</span></div>
				<div class="product_advantage" ref="advantage"  v-html="information.productCase"></div>
			</div>
			<div ref="guide" class="guide padding_content  card_box mt_4" v-if="information.compensationHint">
				<div  class="main_title flex_start"><div class="line_active"></div><span>理赔指南</span></div>
				<div class="guide_content_box" v-html="information.compensationHint"></div>
			</div>
			<div ref="question" class="question padding_content card_box mt_4" v-if="questionListShow?.length">
				<div class="main_title flex_start"><div class="line_active"></div><span>常见问题</span></div>
				<div class="cont" v-for="(item, index) in questionListShow" :key="index" :class="{ cont_line: index }">
					<div class="que">
						<span class="icon que_icon"></span>
						<span class="text" v-text="item.clauseQuestion"></span>
					</div>
					<div class="ans">
						<span class="icon ans_icon"></span>
						<span class="text" v-text="item.clauseAnswer"></span>
					</div>
				</div>
				<div class="see_more" v-if="false" v-show="!questionListShowAll" @click="questionListShowAll = true">
					<span>查看更多</span>
					<span class="icon arrow_right"></span>
				</div>
			</div>
			<div ref="example" class="example padding_content card_box mt_4" v-if="information.productIntroduce">
				<div class="main_title flex_start"><div class="line_active"></div><span>实际案例</span></div>
				<p class="text_break" v-html="information.productIntroduce">出行目的为境外务工的人员，是否可以购买此产品？工的人员，是否可以购买此产品？出行目的为境外务工的人员，是否可以购买此产品？工的人员，是否可以购买此产品？</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'cpsInsureProductNarrate', // 产品解读组件

	props: {
		information: {
			type: Object,
			required: true,
		},
		questionList: {
			type: Array,
			required: true,
		},
	},
	computed: {
		questionListShow() {
			let result = [];
			if (this.questionList.length) {
				if (!this.questionListShowAll) {
					result = this.questionList.slice(0, 3);
				} else {
					result = this.questionList;
				}
			}
			return result;
		},
	},
};
</script>
